import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import MonthCalander from "../../../components/calendar/calendar-month";
import { useDispatch } from "react-redux";
import WeekCalander from "../../../components/calendar/calendar-week";
import DayCalendar from "../../../components/calendar/calendar-day";
const MyReservation= () => {

    const [calendarType, setCalendarType] = useState('week');
    const [calendarDate, setCalendarDate] = useState(new Date);
    const dispatch = useDispatch();
    const calendarDayRef = useRef(null);
    const [filteredData, setFilteredData] = useState([]);
    const [courtLength, setCourtLength] = useState([]);

    const HeaderItems = [
        { id: 4, type: "legend" , legendItems: ["Private", "Scheduled"]},
        { id: 1, type: "filter" },
        { id: 2, type: "dropdown", placeholder: 'Select', options: [{ id: "day", name: "Day" }, { id: "week", name: "Week" }, { id: "month", name: "Month" }], value: calendarType, disabled: false },
    ];

    const handleFilterData = (data) => {
        setFilteredData(data);
    }

    const onPageAction = (data) => {

            setCalendarType(data?.selectedValue)

    }

    const setDate = (e) => {
        setCalendarDate(new Date(e))
    }

    useEffect(() => {
        //console.log("Selected Calendar Date: ", calendarDate);
    }, [calendarDate]);

    const handleBack = () => {
        const newDate = new Date(calendarDate)
        if (calendarType === "week") {
            setCalendarDate(new Date(newDate.setDate(calendarDate.getDate() - 7)))
        } else if (calendarType === "month") {
            setCalendarDate(new Date(newDate.setMonth(calendarDate.getMonth() - 1)))
        } else {
            setCalendarDate(new Date(newDate.setDate(calendarDate.getDate() - 1)));darf
        }
    }


    const handleNext = () => {
        const newDate = new Date(calendarDate)
        if (calendarType === "week") {
            setCalendarDate(new Date(newDate.setDate(calendarDate.getDate() + 7)))
        } else if (calendarType === "month") {
            setCalendarDate(new Date(newDate.setMonth(calendarDate.getMonth() + 1)))

        } else {
            setCalendarDate(new Date(newDate.setDate(calendarDate.getDate() + 1)));
        }
    }

    const handleChildData = (dataFromChild) => {
        //console.log("Data from child:", dataFromChild);
        setCourtLength(dataFromChild);
    };

    return (
        <>

            <PageHeader
                PageTitle="My Reservation"
                titleDateRange={true}
                HeaderItems={HeaderItems}
                onPageAction={onPageAction}
                setDate={setDate}
                date={calendarDate}
                onFilterData={handleFilterData}
                nextPrevHandlers={true}
                handleBack={handleBack}
                handleNext={handleNext}
                courtLength={courtLength}
                calendarType={calendarType}
            />


            <div className="page_content">
                {calendarType === "day" && <DayCalendar calendarDate={calendarDate} filteredEventData={filteredData} path={"my-reservation"} ref={calendarDayRef} sendDataToParent={handleChildData} />}
                {calendarType === "week" && <WeekCalander calendarDate={calendarDate} filteredEventData={filteredData} path={"my-reservation"} />}
                {calendarType === "month" && <MonthCalander calendarDate={calendarDate} filteredEventData={filteredData} path={"my-reservation"} />}
            </div>
        </>

    )
}

export default MyReservation;