import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import PrivateSlotReserveComponent from "./components";
import { useLocation } from "react-router-dom";

export default function PrivateSlotReserveDialog({ visible, onChange, data }) {
    const [viewMode, setViewMode] = useState('');
    const location = useLocation();

    useEffect(() => {
        setViewMode(location.pathname == "/schedule-play" ? 'create' : 'detail');
    }, [location])

    
    const headerContent = () => (
        <div className="flex justify-content-between event-view-dialog-header">
            <h1>{data?.event_type_name}</h1>
            {viewMode == "detail" && data?.action && <div className="flex gap-2">
                <i className="ic-edit header-icon ml-2" onClick={() => setViewMode('update')} />
            </div>}
        </div>
    )
    
    const handleOnSuccess = () => {
        onChange();
        setViewMode(location.pathname == "/schedule-play" ? 'create' : 'detail');
        //setEdit(true);
    };


    return (
        <>
            <Dialog
                header={headerContent}
                className="sign_up_dialog"
                headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
                visible={visible}
                style={{ maxWidth: "540px", width: "100%" }}
                position="center"
                draggable={false}
                resizable={false}
                closable={false}
                onHide={onChange}
            >
                <PrivateSlotReserveComponent data={data} actionMode={viewMode} onSuccess={handleOnSuccess} onChange={handleOnSuccess} />
            </Dialog>
        </>
    );
}
