import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import AddEvent from "../../addEvent";
import httpClient from "../../../../../_util/api";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../../../redux/mainSlice";
import VivToast from "../../../../../shared/VivitechToast";
import ConfirmationPopup from "../../../../../shared/ConfirmationPopup";
import { Checkbox } from "primereact/checkbox";
import "../style.scss"

const ReservationCancelComponent = ({ visible, onChange, data, viewMore, onDialogClose }) => {
  const [eventDetails, setEventDetails] = useState(data)
  const toast_Ref = useRef(null);
  const [editData, setEditData] = useState([])
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [is_all, setis_all] = useState(false)
  const dispatch = useDispatch();
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const confirmationPopupRef = useRef(null);
  const [showError, setShowError] = useState(false)
  const [useToken, setUseToken] = useState(data?.use_my_token);
  const isLegacyMember = useSelector((state) => state.mainSlice.isLegacyMember);


  const headerContent = () => (
    <div className="flex justify-content-between align-items-center reservation-cancel-dialog-header">
      <h1 className="">{eventDetails?.event_type_name}</h1>
      <i onClick={onHide} className="ic-close" />

    </div>
  )

  const onLoad = () => {

  }


  /* const onEditEvent = async (data) => {
        dispatch(showLoader())
    try {
      const resp = await httpClient.post("/edit-slot", {
        event_id: data.event_id,
        occurrence_date: data.occurrence_date,
        start_time: data.start_time,
        end_time: data.end_time,
        court_id: data?.court_id
      });
      
      if (resp?.status === 200) {
        setEditData(resp.data.data)
        setShowAddEvent(true)
        onChange()
        if (onDialogClose) {
          onDialogClose()
        }
      }
      
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
    }
    dispatch(hideLoader())
  }
    
  const onDeleteEvent = async (data) => {
    setDeleteData(data)
      
    if (data.is_recurrent === 1 || data.is_recurrent === true) {
      setDeleteConfirmDialog(true)
        
    } else {
      confirmationPopupRef.current.showPopUp()
    }
        
  } */

  const onCheckIsAll = async () => {
    confirmationPopupRef.current.showPopUp()
  }

  const onHide = () => {
    confirmationPopupRef.current?.hidePopUp()
    onChange()
  }

  const onConfirm = async () => {
    cancelReservation()
  }
  
  const onCancelReservation = async () => {
    confirmationPopupRef.current.showPopUp()
  }
  
  const cancelReservation = async () => {
    try {
      dispatch(showLoader())
      const response = await httpClient.get(`cancel-reservation-scheduled/${data?.reservation_id}`);
      if (response?.status === 200) {
        //console.log(response.data.message);


        toast_Ref.current?.showMessage(
          "success",
          response.data.message,
          "",
          "ic-square-check"
        )
        dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
        setTimeout(() => {
          onChange()
          if (onCloseDialog) {
            onDialogClose()
          }
        }, 1000)
      }

    } catch (error) {
      //console.log(error?.response?.data);

      if (error?.response?.data?.errors?.modal) {
        tokenPurchasePopupRef.current.showPopUp();
      } else {
        toast_Ref.current?.showMessage(
          "error",
          error?.response?.data?.message || "An error occurred",
          "",
          "ic-error-notify"
        );
      }
    }
    dispatch(hideLoader())
  }


  const footerContentEditDialog = (
    <div className="mt-3 flex justify-content-end align-items-center gap-3">
      <Button
        label="Cancel"
        onClick={() => setDeleteConfirmDialog(false)}
        className="btn-secondary w-8rem"
      />
      <Button label="Ok" onClick={onCheckIsAll} className="btn-primary w-8rem" />
    </div>
  );


  const eventViewFooterContent = (
    showError ? <div className="mt-3 flex justify-content-center align-items-center gap-3">
      <p className="error-message-view-event"><i className="ic-exclamation-circle"></i> You can not edit the reservation slot <br /> &nbsp;&nbsp;&nbsp;&nbsp; as court 1 has been booked.</p>
    </div> : (eventDetails?.courts ? <div className="event-list-footer pt-5 mt-1"> </div> : <div></div>)
  );


  const footerContent = (
    <div className=" flex justify-content-end align-items-center gap-3 w-full pt-5 mt-1  ">
      <div className="reservation-cancel-footer-btn">
        {!viewMore && <Button
          label="Close"
          onClick={onHide}
          className="btn-secondary w-full"
        />}
      </div>
      <div className="reservation-cancel-footer-btn">
        <Button label="Cancel Reservation" onClick={onCancelReservation} className="btn-primary w-full" />
      </div>
    </div>
  );

  function formatDateString(dateString) {
    if (dateString) {
      const [dayOfWeek, monthAndDay] = dateString.split(', ');

      const shortDayOfWeek = dayOfWeek.slice(0, 3);
      const [month, day] = monthAndDay.split(' ');
      const shortMonth = month.slice(0, 3);

      return `${shortDayOfWeek}, ${shortMonth} ${day}`;
    }

  }


  function formatTimeRange(startTime, endTime) {
    if (startTime && endTime) {
      const formatTime = (time) => {
        const [hour, minutePart] = time.split(':');
        const [minute, period] = minutePart.split(' ');

        let hourNum = parseInt(hour, 10);

        return `${hourNum} ${period}`;
      };

      const formattedStartTime = formatTime(startTime);
      const formattedEndTime = formatTime(endTime);

      return `${formattedStartTime} - ${formattedEndTime}`;
    }

  }


  return (
    <>
      <VivToast ref={toast_Ref} />
      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Confirm"
        message={"Are you sure you want to cancel this reservation?"}
        onConfirm={onConfirm}
        onCancel={() => confirmationPopupRef.current.hidePopUp()}
        onHide={() => confirmationPopupRef.current.hidePopUp()}// Hide popup on "No"
      />
      {/* <SuccessPopup
      ref={successPopupRef}
        title=""
        message={""}
        messageTitle={"Reservation slot deleted successfully"}
        onHide={() => successPopupRef.current?.hidePopUp() } /> */}
      <Dialog
        header={deleteData?.event_type_name}
        visible={deleteConfirmDialog}
        draggable={false}
        className="success_popup edit_is_all_dialog"
        style={{ width: "33vw" }}
        onHide={() => setDeleteConfirmDialog(false)
        }
        footer={footerContentEditDialog}
        closable={true}
      >
        <div className="flex justify-content-start align-items-center gap-2">
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={false}
            onChange={(value) => setis_all(value.value)}
            checked={is_all === false}
          />
          <label htmlFor="never" className="ml-2 text-base">This event</label>
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={true}
            className="ml-3"
            onChange={(value) => setis_all(value.value)}
            checked={is_all === true}
          />
          <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
        </div>
      </Dialog>
      {/* <AddEvent visible={showAddEvent} onChange={() => setShowAddEvent(false)} data={editData} type={type}/> */}
      <AddEvent visible={showAddEvent} onChange={() => setShowAddEvent(false)} data={editData} />





      <main className={`w-full mt-1  overflow-hidden px-2`}>
        <section className="w-full grid grid-nogutter mt-1 pt-1">
          <div className="reservation-cancel-schedule gap-2 col-6">
            <i className="ic-reservation" />
            <div className="flex flex-column">
              <p className="">Reservation Price</p>
              <span> {eventDetails?.token_per_reservation} Tokens per reservation  </span>
            </div>
          </div>

          <div className="reservation-cancel-schedule gap-2 col-6">
            <i className="ic-clock" />
            <div className="flex flex-column">
              <p className="">Time</p>
              <span> {formatDateString(eventDetails?.occurrence_date_formated)} &nbsp;&nbsp;&nbsp;  {formatTimeRange(eventDetails?.start_time, eventDetails?.end_time)} </span>
            </div>
          </div>
        </section>

        {(eventDetails?.courts != null || true) && (<section className="w-full mt-5 grid grid-nogutter">
          <div className="reservation-cancel-schedule gap-2 col-6">

            <i className="ic-location" />

            <div className="flex flex-column">
              <p className="">Location</p>
              <span> {eventDetails?.location_name} </span>
            </div>

          </div>

        </section>)}
        
        
        {isLegacyMember && <div className="event-view-schedule gap-2 mt-5 col-12 md:col-6">
          <i className="ic-orangecoins"></i>
          <div className="flex flex-column">
            <p className="reserve-headr flex align-items-center">Use My Tokens</p>
            <div className="grid reserve-available-court">
              <div className="col-5 flex gap-1 mt-2">
                <Checkbox
                  checked={useToken}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>}

      </main>
      
      {footerContent}

    </>
  );
}

export default ReservationCancelComponent;