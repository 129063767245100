import React, { useState, useRef, useEffect } from "react";
import { AppMenu } from "./AppMenus";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { showMobileBar, hideMobileBar } from '../redux/sidebarSlice';
import { showLoader, hideLoader, mainSlice } from "../redux/mainSlice";
import httpClient from "../_util/api";
import ConfirmationPopup from "../shared/ConfirmationPopup";

export const AppMobileSideBr = () => {
    const confirmationPopupRef = useRef(null);
    const toast_Ref = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = useSelector((state) => state.mainSlice.role);
    const userInfo = useSelector((state) => state.mainSlice.userdetail);
    const showSideBar = useSelector((state) => state.sidebarSlice.showMobileSideBar);
    const [activeAccount, setActiveAccount] = useState('myAccount');
    const dispatch = useDispatch();

    useEffect(() => {
        const item = localStorage.getItem('switch_account');
        if (item == 'true') {
          setActiveAccount('sharedAccount')
        } else {
          setActiveAccount('myAccount')
        }
      }, []);

    const switchAccountToggle = (value) => {
        setActiveAccount(value);
        localStorage.setItem("switch_account", value == "myAccount" ? false : true);
    }

    const handleLogout = () => {
        confirmationPopupRef.current?.showPopUp();
    }

    const accept = async () => {
        dispatch(showLoader());
        try {
            const resp = await httpClient.post("logout");
            if (resp.status === 200) {
                dispatch(hideLoader());
                dispatch(mainSlice.actions.clearUserDetail());
                dispatch(mainSlice.actions.clearUserRole());
                dispatch(mainSlice.actions.clearUserMenus());
                dispatch(mainSlice.actions.clearLocation_id());
                localStorage.removeItem('user-token');
                //navigate("/login");
                window.location.reload();
            }
        } catch (error) {
            toast_Ref.current?.showMessage(
                "error",
                error?.response?.data?.message || "An error occurred",
                "",
                "ic-error-notify"
            );
            dispatch(hideLoader());
        }
    };

    const headerContent = (
        <>
            <div className="flex flex-column">
                <i onClick={() => { dispatch(hideMobileBar()) }} className="cursor-pointer align-self-end mt-3  pi pi-times" />
                <div className="sidebr_header flex flex-column align-items-center gap-1">
                    <img
                        style={{ borderRadius: "50%" }}
                        width={50}
                        height={50}
                        src={userInfo?.image_path ? userInfo?.image_path : "/images/profile-pic.png"}
                        className="cursor-pointer"
                    />
                    <div className="flex flex-column align-items-center" style={{ wordBreak: 'break-word', textAlign: 'center' }}>
                        <div className="text-sm">{userInfo?.first_name} {userInfo?.last_name}</div>
                        <div className="text-xs" >{userInfo?.email}</div>
                    </div>
                </div>
            </div>

            {userInfo?.role_name === "Member" && userInfo?.wallet_shared_by_user_id &&
                <div className="apptoggle-switch mb-3">
                    {/* Toggle options */}
                    <div className={`toggle-option ${activeAccount === "myAccount" ? "active-opt" : ""}`}
                        onClick={() => switchAccountToggle("myAccount")}>
                        My Account
                    </div>
                    <div className={`toggle-option ${activeAccount === "sharedAccount" ? "active-opt" : ""}`}
                        onClick={() => switchAccountToggle("sharedAccount")}>
                        Shared Account
                    </div>

                    <div className="toggle-background" style={{
                        transform: activeAccount === "myAccount" ? "translateX(0)" : "translateX(100%)",
                    }}></div>
                </div>
            }
        </>

    );

    const footerContent = (
        <div className="viv_sidemenu_profile_tab">
            <Link to="/profile"  >
                <p className={`flex justify-content-start align-items-center ${location.pathname.includes("profile") && "profile-tab-active"}`}>
                    <i className="pi pi-user sidemenu-profile-tab  mr-3"></i>
                    <span >
                        Profile
                        
                    </span>
                </p>

            </Link>

            <a href="javascript:void(0)" onClick={handleLogout}>
                <p className={`flex justify-content-start align-items-center`}>
                    <i className="pi pi-sign-out mr-3"></i>
                    <span >
                        Log out
                    </span>
                </p>
            </a>
        </div>

    )

    return (
        <>
            <ConfirmationPopup
                ref={confirmationPopupRef}
                closable={true}
                title="Logout"
                message="Are you sure you want to logout?"
                onConfirm={() => {
                    accept(); // Handle form submission on "Yes"
                    confirmationPopupRef.current?.hidePopUp(); // Hide popup
                }}
                onCancel={() => confirmationPopupRef.current?.hidePopUp()}
                onHide={() => confirmationPopupRef.current?.hidePopUp()}// Hide popup on "No"
            />

            <Dialog header={''} footer={footerContent} className="sidebar_dialog" visible={showSideBar} position={"left"} style={{ width: '75dvw', height: '100vh' }}
                draggable={false}
                closeOnEscape={true}
                resizable={false}>
                {headerContent}
                <AppMenu />
            </Dialog>
        </>


    );
};
