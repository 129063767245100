import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import "../../assets/layout/_notification.scss";
import { hideLoader, showLoader } from "../../redux/mainSlice";
import { useDispatch, useSelector } from 'react-redux';
import httpClient from "../../_util/api";
import { Divider } from 'primereact/divider';
import PageHeader from "../../shared/PageHeader";
import "./style.scss"
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import VivToast from "../../shared/VivitechToast";


const NotificationDetailView = () => {
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();

  const [notificationData, setNotificationData] = useState([]);
  const [recordfrom, setRecordfrom] = useState(0);
  const [recordto, setRecordto] = useState(0);
  const [totalrecord, setTotalrecord] = useState(notificationData.length);
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const onPageChange = (event) => {
    const page = Math.floor(event.first / event.rows) + 1; // Calculate page number
    setCurrentPage(page);
    setRowsPerPage(event.rows);
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 120, value: 120 }
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            No. of entries:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          Page {options.currentPage} of {options.totalPages}
        </span>
      );
    }
  };


  const handleInvitation = async (button, notificationId) => {
    const url = button === 'Reject'
      ? `invitation-decline/${notificationId}`
      : `invitation-accept/${notificationId}`;
    try {
      dispatch(showLoader());
      const resp = await httpClient.get(url);
      if (resp && resp.status === 200) {
        //console.log(resp);
        toast_Ref.current?.showMessage(
          "success",
          resp?.data?.message,
          "",
          "ic-square-check"
        )
        viewallnotificationapi();
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message,
        "",
        "ic-error-notify"
      );
    }
    dispatch(hideLoader());
  };

  const viewallnotificationapi = async (page = currentPage, per_page = rowsPerPage) => {
    dispatch(showLoader());
    try {
      let resp = await httpClient.get(`notifications?page=${page}&per_page=${per_page}`);
      if (resp?.status === 200) {
        const notifications = resp?.data?.data;
        setNotificationData(notifications?.data);
        setTotalrecord(notifications?.pagination.total);
        setRecordfrom(notifications?.pagination.from);
        setRecordto(notifications?.pagination.to);
        //setCurrentPage(notifications?.current_page)
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };


  useEffect(() => {
    viewallnotificationapi(currentPage, rowsPerPage);
    /* console.log(notificationData); */
  }, [currentPage, rowsPerPage]);


  const BookingConfirmation = (message) => {
    // Regular expressions for date and time patterns
    const datePattern = /\b(?:Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),?\s\w+\s\d{1,2}\b/;
    const timePattern = /\b\d{1,2}:\d{2} ?[APMapm]{2} ?(?:to|-) ?\d{1,2}:\d{2} ?[APMapm]{2}\b/;
    // Highlight function
    const highlightText = (text) => {
      const highlightedText = text
        ?.replace(datePattern, '<span class="highlight">$&</span>')
        ?.replace(timePattern, '<span class="highlight">$&</span>');

      return { __html: highlightedText }; // For rendering HTML
    };

    return (
      <div>
        <p dangerouslySetInnerHTML={highlightText(message)}></p>
      </div>
    );
  };




  return (
    <>

      <VivToast ref={toast_Ref} />

      <div className="notification-page-header">
        <PageHeader
          PageTitle={"Notifications"}
          showBackButton={false}
          HeaderItems={[]}
        />
      </div>


      <div className="page_content vvv_notification_component flex flex-column justify-content-between">

        <section className=" hidden md:notification-content-section notification_content_web md:notification-page md:flex md:flex-column ">

          {notificationData?.slice(first, first + rowsPerPage).map((notification) => (
            <div key={notification.id} className="notification-container mt-3 py-2 px-4 flex justify-content-between align-items-center">

              <div className="flex gap-4 align-items-center">
                <p className="notification-title capitalize">
                  {notification.title}
                </p>
                <p className={`notification-message ${!notification.buttons && !notification.occurrence_date && !notification.start_time && !notification.end_time && 'single-message'}`}>
                  {BookingConfirmation(notification.message)}
                </p>
              </div>

              <div className="flex gap-5 align-items-center">



                {notification.buttons && (
                  <div className="flex gap-3">
                    {notification.buttons
                      .sort((a, b) => (a === "Reject" ? -1 : 1))
                      .map((button) => (
                      <>
                        <Button

                          label={button === "Reject" ? "Reject" : button}
                          className={`w-full ${button === "Reject" ? 'btn-tertiary' : 'btn-primary'}`}
                          onClick={() => handleInvitation(button, notification.id)}
                          type="button"
                        />
                      </>
                    ))}

                  </div>
                )}

                {notification.occurrence_date && notification.start_time && notification.end_time && !notification.buttons &&
                  <div className="flex gap-2 ">
                    <i className="ic-clock align-self-center" />
                    {/* <p className="notification-time">Monday, June 17   8pm - 9pm</p> */}
                    <p className="notification-time">{notification.occurrence_date}   {notification.start_time} - {notification.end_time}</p>

                  </div>
                }

                {notification.location && !notification.buttons &&
                  <div className="flex gap-2 ">
                    <img src="./images/pin-location.svg" className="align-self-center" alt="" />
                    <p className="notification-time">{notification.location}</p>

                  </div>
                }

                <p className="notification-timeremain align-self-center">{notification.time_diff}</p>

              </div>

            </div>
          ))}
        </section>

        {/*Mobile View */}

        <section className="notification-content-section p-2 mt-0  md:hidden">

          <section className="notification-content-section notification-page ">
            {/* New Notifications */}
            <h1 className="notification-type">Notifications</h1>
            {notificationData
              /* .filter(notification => notification.type === "new") */
              .slice(first, first + rowsPerPage)
              .map((notification, index, currentPageData) => (
                <div key={notification.id} className={` noti-static flex flex-column`}>
                  <h1 className="capitalize">{notification.title}</h1>

                  <div className="noti-static-msg flex flex-row justify-content-between">
                    <p>{BookingConfirmation(notification.message)}</p>
                    <span>{notification.time_diff}</span>
                  </div>

                  {/* Timeframe and Location display */}
                  {notification.start_time && notification.end_time && (
                    <div className="noti-timeloc-frame-msg mt-2 flex flex-row">
                      <div className="noti-time-frame-msg w-full flex flex-row justify-content-start gap-3 align-items-center">
                        <div className="flex justify-content-start align-items-center gap-2">
                          <i className="ic-clock"></i>
                          <span>{notification.start_time} - {notification.end_time}</span>
                        </div>
                        {notification.location && (
                          <>
                            <div className="flex justify-content-start align-items-center gap-2">
                              <i className="ic-location"></i>
                              <span>{notification.location}</span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Buttons */}
                  {notification.buttons && (
                    <div className="noti-static-btn-cont mt-3 flex flex-col gap-3">
                      {notification.buttons
                        .sort((a, b) => (a === "Reject" ? -1 : 1))
                        .map((button) => (
                        <>
                          <Button

                            label={button === "Reject" ? "Reject" : button}
                            className={`w-full ${button === "Reject" ? 'btn-tertiary' : 'btn-primary'}`}
                            onClick={() => handleInvitation(button, notification.id)}
                            type="button"
                          />
                        </>
                      ))}

                    </div>
                  )}

                  {/* Divider between notifications */}
                  {index < currentPageData.length - 1 && <Divider />}
                </div>
              ))}


          </section>

        </section>

        <div className="viv_paginator pt-0 pb-0  align-items-center flex justify-content-between border-top-1 border-300 mt-2">

          <div className="pagginator-width">
            <Paginator
              first={(currentPage - 1) * rowsPerPage}
              rows={rowsPerPage}
              totalRecords={totalrecord ? totalrecord : 0}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onPageChange={onPageChange}
              template={paginatorTemplate}
            />
          </div>
          <div>
            <span className="p-paginator-current pb-1">{`Showing: ${recordfrom ? recordfrom : 0
              }-${recordto ? recordto : 0} of ${totalrecord ? totalrecord : 0
              } entries`}</span>
          </div>
        </div>

      </div>
    </>
  );
};

export default NotificationDetailView;
